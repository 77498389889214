@import "../../styles/variables";

header {
    a:link,a:visited {
        text-decoration: none !important;
    }
}

.vertical_center {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.outer_container {
    z-index: 10;
    display: flex;
    width: 100%;
    height: fit-content;
    padding: 1.5rem 0;
    position: fixed;
}

.inner_container {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin: auto;
    height: 4rem;
    width: 100vw;
    // max-width: 70vw;
    border-radius: 0; //var(--module-border-radius);
    // background-color: rgba(255,255,255,0.7);
    // backdrop-filter: blur(10px);

    .img_container {
        position: relative;
        display: flex;
        width: 3.5rem;
        height: 3.5rem;
        transition: transform 0.4s ease;

        &.onScroll {
            transition: transform 0.4s ease;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            -webkit-filter: drop-shadow(0 0 25px rgba(0,0,0,0.3));
            filter: drop-shadow(0 0 25px rgba(0,0,0,0.3));
        }

        p {
            margin-left: 2.8rem;
        }
    }

    .cuter_text,p {
        font-family: Monospace, serif;
        font-size: 20px;
        margin: 0;
        color: white;
        text-decoration: none !important;
        letter-spacing: 0.01em;
    }
}

@keyframes down {
  0% {
    transform: translateY(-10rem); /* Start at initial position */
  }
  70% {
    transform: translateY(10px); /* Move down slightly */
  }
  100% {
    transform: translateY(0); /* Move up out of the screen */
  }
}

@keyframes up {
  0% {
    transform: translateY(0); /* Start at initial position */
  }
  30% {
    transform: translateY(20px); /* Move down slightly */
  }
  100% {
    transform: translateY(-10rem); /* Move up out of the screen */
  }
}

.animate_down {
  animation: down 0.32s  ease-in-out forwards;
}

.animate_up {
  animation: up 0.32s  ease-in-out forwards;
}