@import "../../styles/variables";

footer {
    bottom:0;
    left: 0;
    right: 0;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: space-around;
    background-color: #171717;
    color: #fff;
    padding: 50px 20vw;

    p {
        white-space: pre-wrap;
        font-size: 14px;
        opacity: 0.8;
        text-align: center;
        line-height: 20px;
        font-size: 14px;
        font-family: Monospace, serif;
    }

    a {
      color: inherit; 
    }
}
