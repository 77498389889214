@import "../../styles/variables";
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

:root {
  --module-border-radius: 10px;
  --header-height: 8rem;

  background-color: #f0f1f2;
}

.outer_container {
    --banner-height: 50%;

    display: grid;
    grid-template-columns: 25vw auto;
    column-gap: 1rem;
    width: 70vw;
    margin: auto;
    padding-top: var(--header-height);

    h2 {
        text-align: center;
        font-weight: 600;
        font-size: 20px;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .profile_container {
        position: relative;
        background-color: white;
        border-radius: var(--module-border-radius);
        overflow: hidden;
        padding-top: var(--banner-height);

        .banner {
            position: absolute;
            height: 0;
            top: 0;
            width: 100%;
            padding-bottom: var(--banner-height);
        }

        .profile {
            position: relative;
            z-index: 2;
            background-color: white;
            height: 0;
            left: 0;
            width: 40%;
            padding-bottom: 40%;
            border-radius: 1000px;
            border: 4px solid white;
            margin-bottom: 0.5rem;
            overflow: hidden;
        }

        .inner_container {
            margin-top: -20%;
            padding: 1rem 2rem;

            h1 {
                margin: 0;
            }
        }
    }

    .module {
        position: relative;
        background-color: rgba(255,255,255,0.9);
        border-radius: var(--module-border-radius);
        overflow: hidden;
        padding: 2rem 0;
    }
}

.floating_module {
    width: 80%;
    padding: 1rem;
    background-color: white;
    border-radius: 15px;
    margin: auto;
    box-shadow: rgba(17, 12, 46, 0.01) 0px 48px 100px 0px;
    -webkit-box-shadow: rgba(17, 12, 46, 0.01) 0px 48px 100px 0px;
    -moz-box-shadow: rgba(17, 12, 46, 0.01) 0px 48px 100px 0px;
}

.settings_module {
    border-radius: var(--module-border-radius);
    width: 100%;
}

.settings_module>div {
    padding: 1rem 10%;

    p {
        font-weight: 600;
    }
}

.module_container {
    --profile-picture-size: 50px;
    display: grid;
    gap: 1rem;

    .transaction_module {
        padding: 1rem 2rem;
        display: grid;
        grid-template-columns: calc(var(--profile-picture-size) + 1rem) 1fr;
    }

    .goal_module,.transaction_module {
        p {
            font-weight: 600;
        }

        .profile_picture {
            height: var(--profile-picture-size);
            width: var(--profile-picture-size);
            margin-right: 1rem;
            background-color: #EEE;
            border-radius: 100px;
            overflow: hidden;
        }

        .progress_bar_container {
          width: 100%;
          background-color: #e0e0e0;
          border-radius: 25px;
          overflow: hidden;
        }

        .progress_bar {
          height: 25px;
          background-color: #76c7c0;
          width: 0;
          border-radius: 25px 0 0 25px;
          transition: width 0.3s ease;
        }

        .progress_bar span {
          padding: 5px;
          font-weight: bold;
          color: white;
          text-align: center;
          display: block;
        }
    }

    .vertical_grid {
        display: grid;
        gap: 2px;
    }

    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;

        p {
            margin: 0;
            font-weight: 400;
        }
    }
}
