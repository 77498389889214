@import "../../styles/variables";

.parent {
    .contain {
        overflow: hidden;
        width: 100%;
        height: 100%;
    }
    .image {
        width: 100%;
        height: 100%;
    }
}

.parent_banner {
    width: 100%;
    height: 0;
    padding-bottom: 50%;
    .child {
        padding-bottom: 50%;
        width: 100%;
    }
}