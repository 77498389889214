@import "../../styles/variables";
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

:root {
  --module-border-radius: 20px;
  --header-height: 8rem;

  background-color: #f0f1f2;
}

body {
    min-height: 100vh;
}

.vertical_center {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}


.outer_container {
    --banner-height: 50%;

    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    width: 70vw;
    margin: auto;
    padding-top: var(--header-height);

    .button {
        position: absolute;
        bottom: 2rem;
        width: 5rem;
        left: 0;
        right: 0;
        margin: auto;
        background-color: black;
        padding: 1.2rem 3rem;
        margin: auto;
        border-radius: 100px;

        text-align: center;
        color:  white;
    }

    .sign_in_button {
        margin-top:  1rem;
        cursor: pointer;
        width: fit-content;
        background-color: black;
        padding: 0.8rem 3rem;
        border-radius: 10px;
        border: 1px solid #454545;

        text-align: center;
        font-size: 14px;
        color:  white;
    }

    .horizontal_line {
        width: 100%;
        height: 3rem;
        position: relative; /* Required to position the :after element relative to this div */
    }

    .horizontal_line:after {
        content: "";
        display: block;
        width: 40%; 
        height: 2px;
        border-radius: 10px;
        background-color: white;
        position: absolute;
        right: 0;
        bottom:  1.5rem;
    }


    .dynamic_text_container {
        text-align: center;
        margin: 1.5rem 0;
        span {
            font-family: Monospace, serif;
            font-size: 26px;
            font-weight: 600;
        }
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .profile_container {
        position: relative;
        height: 300px;
        background-color: white;
        border-radius: var(--module-border-radius);
        overflow: hidden;
        padding-top: var(--banner-height);

        .banner {
            position: absolute;
            height: 0;
            top: 0;
            width: 100%;
            padding-bottom: var(--banner-height);
        }

        .profile {
            position: relative;
            z-index: 2;
            background-color: white;
            height: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 40%;
            padding-bottom: 40%;
            border-radius: 1000px;
            border: 4px solid white;
            margin-bottom: 0.5rem;
            overflow: hidden;
        }

        .inner_container {
            margin-top: -20%;
            padding: 1rem 2rem;

            h1 {
                margin: 0;
            }
        }
    }

    .module {
        position: relative;
        height: fit-content;
        overflow: hidden;

        p {
            text-align: right;
            color: white;
            font-family: Monospace, serif;
            font-size: 24px;
            font-weight: 500;
        }
    }
}
