@import "./styles/variables";
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

input[type="text"] {
    height: 50px; /* Adjust height as needed */
}

input[type="text"],select,textarea,.card_element {
    resize: none;
    width: 100%; /* Adjust width as needed */
    background-color: #F1F1F1; /* Light gray background */
    border: none; /* Remove default border */
    border-radius: 22px; /* Make the input field rounded */
    padding: 20px 20px; /* Add some padding to the left and right */
    box-sizing: border-box; /* Ensure padding is included in width */
    font-size: 14px; /* Adjust font size as needed */
    outline: 1px solid #E5E5E5; /* Remove the default outline on focus */
}

/* Placeholder text style */
input[type="text"]::placeholder,textarea::placeholder {
    color: #666666; /* Slightly darker gray */
    opacity: 1; /* Ensure the color is fully opaque */
}

/* Optional: Add focus effect */
input[type="text"]:focus {
    // box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Add a subtle shadow on focus */
}