@import "../../styles/variables";
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

body {
    min-height: 100vh;
}

.wrapper {
    padding-top: var(--header-height);
}

.wrapper>p {
    text-align: center;
    font-size: 20px;
}

.button {
    cursor: pointer;
    height: fit-content;
    display: block;
    width: fit-content;
    margin: auto;
    background-color: black;
    padding: 1.2rem 2rem;
    margin: 1rem auto auto;
    border-radius: 100px;

    text-align: center;
    color:  white;
}

.outer_container {
    --banner-height: 50%;

    display: grid;
    column-gap: 1rem;
    width: 70vw;
    margin: 3rem auto auto;

    &.one_col {
        grid-template-columns: auto;
        width: 60vw;
        max-width: 550px;
    }

    &.two_col_a {
        grid-template-columns: 25vw 25vw;
        width: 50vw;
    }

    &.two_col_b {
        grid-template-columns: 25vw auto;
        width: 70vw;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .profile_container {
        position: relative;
        height: 300px;
        background-color: white;
        border-radius: var(--module-border-radius);
        overflow: hidden;
        padding-top: var(--banner-height);

        .banner {
            position: absolute;
            height: 0;
            top: 0;
            width: 100%;
            padding-bottom: var(--banner-height);
        }

        .profile {
            position: relative;
            z-index: 2;
            background-color: white;
            height: 0;
            left: 0;
            width: 40%;
            padding-bottom: 40%;
            border-radius: 1000px;
            border: 4px solid white;
            margin-bottom: 0.5rem;
            overflow: hidden;
        }

        .inner_container {
            margin-top: -20%;
            padding: 1rem 2rem;

            h1 {
                margin: 0;
            }
        }
    }

    .module {
        position: relative;
        background-color: white;
        border-radius: var(--module-border-radius);
        overflow: hidden;
        padding: 2rem;

        p {
            margin: 1rem 0;
            font-family: Roboto, serif;
            letter-spacing: 0.02em;
            font-weight: 600;
            color: black;
        }
    }
}
